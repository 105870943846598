<template>
    <div>

        <div id="my-modal" class="modal fade">
            <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title"><b>Add to <span style="color:#328af1;">CLIENT REPORTING</span></b></h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="forwardclose">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">

                    <!-- <Vueditor  ref="editor" ></Vueditor> -->

                    <vue-editor v-model="editor" :editorToolbar="customToolbar"></vue-editor>

                </div>
                <div class="modal-footer">
                    <button type="button" @click="forward" class="btn btn-primary">Submit</button>
                    <button type="button" @click="forwardclose" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                </div>
            </div>
            </div>
        </div>

        <!-- TextArea Opens only When the Reply button is Clicked -->
            <div id="reply" class="modal fade">
                <form ref="myreplyForm">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title"><b><span style="color:#328af1;">REPLY </span>To</b></h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="forwardclose">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div class="modal-body">

                            <!-- <Vueditor  ref="editor1" ></Vueditor> -->

                            <vue-editor v-model="editor1" :editorToolbar="customToolbar"></vue-editor>

                        </div>
                        <div class="modal-footer">
                            <button type="button" @click="reply" class="btn btn-primary">Submit</button>
                            <button type="button" @click="forwardclose" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
                </form>
            </div>
        <!-- TextArea Ends -->

        <!-- Message Display -->
            <div v-for="(chat,index) in order.chats" :key="chat.id" :index="index">

                <span v-if="order.chats[index].reply_id === replyid">

                    <div v-if="chat.name === 'projectmanager'" class="DmMsgBox">

                        <div class="row">

                            <div class="col-md-1"></div>

                            <div class="col-md-1">
                                <img :src="chat.gravatar" class="rounded-circle" alt="Avatar" id="msg-img-style"/>
                            </div>

                            <div class="col-md-4" id="msg-img-name"><b>{{ chat.creator_name }}</b>
                            <small id="msg-small-style"> &nbsp;
                                <vue-moments-ago prefix="said" suffix="ago" :date=chat.created_at lang="en"></vue-moments-ago>
                            </small></div>

                        </div>

                        <div class="row">

                            <div class="col-md-1"></div>

                            <div class="col-md-11" id="container1" style="background-color:#F8F8FF">

                                <!-- <p style="white-space: pre-wrap;">{{ chat.body }}</p> -->
                                <p style="white-space: pre-wrap;" v-html="chat.body"></p>
                                <span class="time-right">
                                    <span v-if="myrole === 'accounts'">
                                    <button @click="replyClicked(chat)" class="btn btn-link font-weight-bolder" style="text-decoration:none;font-size: 12px">REPLY</button>
                                    </span>
                                    {{ chat.created_at | formatDate}} (UTC)
                                </span>

                                <span v-if="chat.name === 'projectmanager'">
                                    <span v-if="myrole === 'reseller' || myrole === 'staff'" >

                                        <button type="button" @click="forwardClicked(chat)" class="btn btn-outline-primary btn-sm">Add to Client Report</button>

                                    </span>
                                </span>
                            </div>

                        </div>

                    </div>

                    <div v-else class="ClientMsgBox">

                        <div class="row">

                            <div class="col-md-1"></div>

                            <div class="col-md-1">
                                <img :src="chat.gravatar" class="rounded-circle" alt="Avatar" id="msg-img-style"/>
                            </div>

                            <div class="col-md-4" id="msg-img-name"><b>{{ chat.creator_name }}</b>
                            <small id="msg-small-style"> &nbsp;
                                <vue-moments-ago prefix="said" suffix="ago" :date=chat.created_at lang="en"></vue-moments-ago>
                            </small></div>

                        </div>

                        <div class="row">

                            <div class="col-md-1"></div>

                            <div class="col-md-11" id="container1" style="background-color:#F8F8FF">

                                <!-- <p style="white-space: pre-wrap;">{{ chat.body }}</p> -->
                                <p style="white-space: pre-wrap;" v-html="chat.body"></p>
                                <span class="time-right">
                                    <span v-if="myrole === 'accounts'">
                                    <button @click="replyClicked(chat)" class="btn btn-link font-weight-bolder" style="text-decoration:none;font-size: 12px">REPLY</button>
                                    </span>
                                    {{ chat.created_at | formatDate}} (UTC)
                                </span>

                                <span v-if="chat.name === 'projectmanager'">
                                    <span v-if="myrole === 'reseller' || myrole === 'staff'" >

                                        <button type="button" @click="forwardClicked(chat)" class="btn btn-outline-primary btn-sm">Add to Client Report</button>

                                    </span>
                                </span>
                            </div>

                        </div>

                    </div>

                </span>

            </div>
        <!-- Message Ends -->

    </div>

</template>

<script>

    import VueMomentsAgo from 'vue-moments-ago'

    import { VueEditor } from "vue2-editor";

    import * as $ from "jquery";

    export default {
        props: ['order', 'myrole', 'replyid', 'myreply'],

        components: {
            VueMomentsAgo,
            VueEditor
        },

        data() {
            return {
                editor: '',
                editor1: '',
                newTask: '',
                editing: false,
                customToolbar: [
                    ["bold", "italic", "underline"],
                    [{ list: "ordered" }, { list: "bullet" }],
                ]
            };
        },

        created() {

            // window.Echo.private('chats.' + this.order.id)
            //     .listen('ChatCreated', ({chat}) => {
            //         this.addTask(chat);
            // });

        },

        methods: {

            forwardclose()
            {
                $("#my-modal").modal('hide');
            },

            forwardClicked: function(chat) {
                this.orderid = chat.order_id;
                this.editor = '<span style=" white-space: pre-wrap; font-family: &quot;Nunito&quot;, sans-serif; font-size: 0.9rem; line-height: 1.6; text-decoration: none !important;">' + chat.body + '</span>';
                $("#my-modal").modal('show');
            },

            forward()
            {
                // var trimLeftMsg = this.editor.substring(0,154);

                // var trimRightMsg = trimLeftMsg.substring(0, trimLeftMsg.length-7);

                var trimLeftMsg  = this.editor;
                var trimRightMsg = this.editor;

                const headers = {
                    'Message' : trimRightMsg,
                    'orderid' : this.orderid
                }

                axios.post('/forward', {
                    headers
                })
                .then(function (response) {
                    console.log('Success');
                    console.log(response);
                })
                .catch(function (error) {
                    console.log('Failure');
                    console.log(error);
                });

                $("#my-modal").modal('hide');

            },

            replyClicked: function(chat) {
                this.getreplyid = this.replyid;
                this.myreply = chat.body;
                $("#reply").modal('show');
            },

            reply: function() {

                // if(!this.$refs.editor1.getContent() == 0) {
                if(!this.editor1.length() == 0) {

                axios.post(`/api/myorders/${this.order.id}/chats`, {
                        body: this.editor1,
                        reply_id: this.getreplyid,
                    })
                    .then(response => response.data)
                    .then(this.addTask);

                axios.post('https://www.submitinme.com/home/reseller_postmessage.aspx', {

                    SIM_Orderid: this.order.sim_order_id,

                    SIM_Msg: this.editor1
                })
                .then(function (response) {
                    console.log(response);
                })

                $("#reply").modal('hide');

                // Clearing Form
                this.editor1 = '';
                this.$refs.myreplyForm.reset();

                }

            },

            addTask(chat) {
                this.order.chats.push(chat);
                this.newTask = '';
            }
        }
    }
</script>

<style scoped>

    #container1,
    .container1 {
    background-color: #f6f6f6;
    border-radius: 5px;
    margin: 10px 0;
    padding: 10px 0 0 0;
    border-bottom: 3px solid #c5c5c5;
    }

    .ClientMsgBox #container1,
    .ClientMsgBox .container1 {
    background-color: #dce8eb;
    border-bottom: 3px solid #b2c1c5;
    }

    #container1 p {
        padding: 10px 20px;
    }

    #container1 .time-right {
        color: #666;
        float: right;
        font-size: 12px;
        background: #dedede;
        border-radius: 10px 0 0 0;
        padding: 5px 20px;
    }

    .ClientMsgBox #container1 .time-right {
        color: #222;
        background: #b2c1c5;
        padding: 5px 20px;
    }

    #msg-btn {
        border-radius: 5px;
        float: right;
        padding: 15px 50px;
    }

    #container1 span .btn-outline-primary,
    .ClientMsgBox #container1 span .btn-outline-primary {
        margin: 10px 0 5px 15px
    }


</style>