<template>
    <div>

        <div class="form-group" id="msg-textarea">
            <div class="shadow p-3 mb-5 bg-white rounded">
                <div class="row">
                    <textarea class="form-control"  rows="6" placeholder="Have something to say ?" style="border: none; background-image: linear-gradient(rgb(255,0,0,0.03),rgb(255,0,0,0.03));" required v-model="newTask" ></textarea>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-12">
                <button type="submit" class="btn btn-primary"  @click="save" id="msg-btn"><b>SEND</b></button>
            </div>
        </div>

        <pmb-chat :order="order" :myrole="myrole"></pmb-chat>

    </div>

</template>

<script>

    import VueMomentsAgo from 'vue-moments-ago'

    import PmbChat from './PmbChat'

    import * as $ from "jquery";

    export default {
        props: ['data-project', 'role'],
        data() {
            return {
                newTask: '',
                order: this.dataProject,
                myrole: this.role,
                message: '',
                orderid: '',
            };
        },

        components: {
            VueMomentsAgo,
            PmbChat
        },

        created() {

            window.Echo.private('custompmbs.' + this.order.id)
                .listen('CustomPmbCreated', ({chat}) => {
                    this.addTask(chat);
            });

        },

        methods: {

            save() {

                // var instance = axios.create();
                // delete instance.defaults.headers.common['Authorization'];

                axios.post(`/api/customorders/${this.order.id}/custompmbs`, {
                    body: this.newTask
                })
                .then(response => response.data)
                .then(this.addTask);

            },

            addTask(chat) {
                this.order.custompmbs.push(chat);
                this.newTask = '';
            }
        }
    }
</script>
