<template>
    <div>

        <div id="my-modal" class="modal fade">
            <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title"><b>Add to <span style="color:#328af1;">Project Message Board</span></b></h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="forwardclose">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                    <!-- <Vueditor  ref="editor" ></Vueditor> -->

                    <vue-editor v-model="htmlEditor" :editorToolbar="customToolbar"></vue-editor>

                </div>
                <div class="modal-footer">
                    <button type="button" @click="forward" class="btn btn-primary">Submit</button>
                    <button type="button" @click="forwardclose" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                </div>
            </div>
            </div>
        </div>


        <div class="form-group" id="msg-textarea">
            <div class="shadow p-3 mb-5 bg-white rounded">
                <div class="row">
                    <textarea class="form-control RmsgTextArea"  rows="6" placeholder="Have something to say ?" required v-model="newTask" ></textarea>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-12">
                <button type="submit" class="btn btn-primary"  @click="save" id="msg-btn"><b>SEND</b></button>
            </div>
        </div>

        <div v-for="message in order.messages.slice().reverse()" :key="message.id">

            <div v-if="message.name === 'projectmanager'" class="DmMsgBox">

                <div class="row">

                    <div class="col-md-1">
                        <img :src="message.gravatar" class="rounded-circle" alt="Avatar" id="msg-img-style"/>
                    </div>

                    <div class="col-md-5" id="msg-img-name"><b>{{ message.creator_name }}</b>
                    <small id="msg-small-style"> &nbsp;
                        <vue-moments-ago prefix="said" suffix="ago" :date=message.created_at lang="en"></vue-moments-ago>
                    </small></div>

                </div>

                <div class="row">

                    <div class="col-md-12" id="container1">

                        <p style="white-space: pre-wrap;" v-html="message.body"></p>
                        <span class="time-right">{{ message.created_at | formatDate}} (UTC)</span>

                        <span v-if="myrole === 'reseller' || myrole === 'staff'">
                        <!-- <span v-if="myrole === 'subakrishnan135@gmail.com'"> -->

                            <button type="button" @click="forwardClicked(message)" class="btn btn-outline-primary btn-sm">Add to PMB</button>

                        </span>

                    </div>

                </div>

            </div>

            <div v-else class="ClientMsgBox">

                <div class="row">

                    <div class="col-md-1">
                        <img :src="message.gravatar" class="rounded-circle" alt="Avatar" id="msg-img-style"/>
                    </div>

                    <div class="col-md-5" id="msg-img-name"><b>{{ message.creator_name }}</b>
                    <small id="msg-small-style"> &nbsp;
                        <vue-moments-ago prefix="said" suffix="ago" :date=message.created_at lang="en"></vue-moments-ago>
                    </small></div>

                </div>

                <div class="row">

                    <div class="col-md-12" id="container1">

                        <p style="white-space: pre-wrap;" v-html="message.body"></p>
                        <span class="time-right">{{ message.created_at | formatDate}} (UTC)</span>

                        <span v-if="myrole === 'reseller' || myrole === 'staff'">
                        <!-- <span v-if="myrole === 'subakrishnan135@gmail.com'"> -->

                            <button type="button" @click="forwardClicked(message)" class="btn btn-outline-primary btn-sm">Add to PMB</button>

                        </span>

                    </div>

                </div>

            </div>

        </div>

    </div>

</template>

<script>

    import VueMomentsAgo from 'vue-moments-ago'

    import { VueEditor } from "vue2-editor";

    import * as $ from "jquery";

    // import Vueditor from 'vueditor'

    // import 'vueditor/dist/style/vueditor.min.css'

    // let config = {
    //     toolbar: [
    //         'bold', 'italic', 'insertOrderedList', 'foreColor', 'link', '|', 'removeFormat', 'undo', 'redo'
    //     ],
    //     uploadUrl: '',
    // };

    // Vue.use(Vueditor, config);

    export default {
        props: ['data-project', 'role'],
        data() {
            return {
                htmlEditor: '',
                newTask: '',
                order: this.dataProject,
                myrole: this.role,
                message: '',
                orderid: '',
                customToolbar: [
                    ["bold", "italic", "underline"],
                    [{ list: "ordered" }, { list: "bullet" }],
                ]
            };
        },

        components: {
            VueMomentsAgo,
            VueEditor
        },

        created() {

            window.Echo.private('messages.' + this.order.id)
                .listen('MessageCreated', ({message}) => {
                    this.addTask(message);
            });
            // .notification((notification) => {
            //     console.log(notification.type);
            // });

            // Echo.private('App.User.1')
            // .notification((notification) => {
            //     console.log(notification);
            // });

        },

        methods: {

            forwardClicked: function(message) {
                this.orderid = message.order_id;

                this.message1 = message.body.replace("target=\"'blank'\"", 'target="blank"');
                this.message2 = this.message1.replace("onclick=\"window.location.href='", 'href=');
                this.message3 = this.message2.replace("'\"", '');

                this.htmlEditor = '<span style="white-space: pre-wrap!important; font-size: 0.9rem; line-height: 1.6; text-decoration: none !important;">' + this.message3 + '</span>';
                $("#my-modal").modal('show');
            },

            forwardclose()
            {
                $("#my-modal").modal('hide');
            },

            forward()
            {
                // var trimLeftMsg = this.htmlEditor.substring(0,154);
                // var trimRightMsg = trimLeftMsg.substring(0, trimLeftMsg.length-7);

                // console.log('trimLeftMsg');
                // console.log(trimLeftMsg);
                // console.log('trimRightMsg');
                // console.log(trimRightMsg);

                var trimLeftMsg  = this.htmlEditor;
                var trimRightMsg = this.htmlEditor;

                const headers = {
                    'Message' : trimRightMsg,
                    'orderid' : this.orderid
                }

                axios.post('/forwardtopmb', {
                    headers
                })
                .then(function (response) {
                    console.log('Success');
                    console.log(response);
                })
                .catch(function (error) {
                    console.log('Failure');
                    console.log(error);
                });

                // console.log('ORDER');
                // console.log(this.order.id);
                // console.log(this.order.sim_order_id);

                axios.post('https://www.submitinme.com/home/reseller_postmessage.aspx', {

                    SIM_Orderid: this.order.sim_order_id,

                    SIM_Msg: trimRightMsg
                })
                .then(function (response) {
                    console.log(response);
                });

                $("#my-modal").modal('hide');

            },

            save() {
                axios.post(`/api/orders/${this.order.id}/messages`, {
                        body: this.newTask
                    })
                    .then(response => response.data)
                    .then(this.addTask)
                ;
            },

            addTask(message) {
                this.order.messages.push(message);
                this.newTask = '';
            }
        }
    }
</script>

<style scoped>

    .RmsgTextArea {
        background-image: linear-gradient(rgb(255,0,0,0.03),rgb(255,0,0,0.03));
        background: #fff;
        border: 1px solid #e1e1e1;
        min-height: 200px;
    }

    #container1,
    .container1 {
    background-color: #f6f6f6;
    border-radius: 5px;
    margin: 10px 0;
    padding: 10px 0 0 0;
    border-bottom: 3px solid #c5c5c5;
    }

    .ClientMsgBox #container1,
    .ClientMsgBox .container1 {
    background-color: #dce8eb;
    border-bottom: 3px solid #b2c1c5;
    }

    #container1 p {
        padding: 10px 20px;
    }

    #container1 .time-right {
        color: #666;
        float: right;
        font-size: 12px;
        background: #dedede;
        border-radius: 10px 0 0 0;
        padding: 5px 20px;
    }

    .ClientMsgBox #container1 .time-right {
        color: #222;
        background: #b2c1c5;
        padding: 5px 20px;
    }

    #msg-btn {
        border-radius: 5px;
        float: right;
        padding: 15px 50px;
    }

    #container1 span .btn-outline-primary,
    .ClientMsgBox #container1 span .btn-outline-primary {
        margin: 10px 0 5px 15px
    }

</style>