/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;

import moment from 'moment';

import * as $ from "jquery";

import swal from 'sweetalert2';
window.Swal = swal;

Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('MM-DD-YYYY hh:mm')
    }
});

window.Popper = require('@popperjs/core').default;
window.$ = window.jQuery = require('jquery');

require('bootstrap');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('example-component', require('./components/ExampleComponent.vue').default);

Vue.component('reply-component', require('./components/ReplyComponent.vue').default);

Vue.component('chat-component', require('./components/ChatComponent.vue').default);

Vue.component('bug-component', require('./components/BugComponent.vue').default);

Vue.component('comment-chat', require('./components/CommentChat.vue').default);

Vue.component('comment-reply', require('./components/CommentReply.vue').default);

// Custom Pmb
Vue.component('custom-pmb', require('./components/CustomPmb.vue').default);

Vue.component('pmb-chat', require('./components/PmbChat.vue').default);

Vue.component('pmb-reply', require('./components/PmbReply.vue').default);

// Custom Reporting
Vue.component('custom-reporting', require('./components/CustomReporting.vue').default);


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});

$(".alert.flash").fadeTo(2000, 500).slideUp(500, function() {
    $(".alert.flash").slideUp(500);
});