<template>

        <div>

        <div id="my-modal" class="modal fade">
            <form ref="mybugForm">
            <div class="modal-dialog" role="document" style="max-width: 600px">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title"><b> <span style="color:#328af1;">Report Bugs / Request New Feature</span></b></h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="forwardclose">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                    <div class="flex border-b border-40">

                        <div class="w-2/5 px-8 py-6">
                            <label for="service_date" class="inline-block text-80 pt-2 leading-tight"><b>Title <span style="color:red">*</span></b><!----></label>
                        </div>

                        <div class="py-6 px-8 w-full">
                            <input v-model="bugtitle" style="width:100%" required> <!---->
                            <div class="help-text help-text mt-2"></div>
                        </div>

                    </div>

                    <div class="flex border-b border-40">

                        <div class="w-2/5 px-8 py-6">
                            <label for="service_date" class="inline-block text-80 pt-2 leading-tight"><b>Type <span style="color:red">*</span></b><!----></label>
                        </div>

                        <div class="py-6 px-8 w-full">
                            <input type="radio" id="one" value="Bug" v-model="bugtype">
                            <label for="one">Bug &nbsp;&nbsp;</label>

                            <input type="radio" id="two" value="Feature" v-model="bugtype">
                            <label for="two">New Feature &nbsp;&nbsp;</label>

                            <input type="radio" id="three" value="Suggestion" v-model="bugtype">
                            <label for="two">Suggestions &nbsp;&nbsp;</label>
                             <!---->
                            <div class="help-text help-text mt-2"></div>
                        </div>

                    </div>

                    <div class="flex border-b border-40">

                        <div class="w-2/5 px-8 py-6">
                            <label for="service_date" class="inline-block text-80 pt-2 leading-tight"><b>Message <span style="color:red">*</span></b><!----></label>
                        </div>

                        <div class="py-6 px-8 w-full">
                            <!-- <Vueditor  ref="editor" placeholder="Description" required></Vueditor>  -->

                            <vue-editor v-model="editor" placeholder="Description" :editorToolbar="customToolbar"></vue-editor> <!---->

                            <div class="help-text help-text mt-2"></div>
                        </div>

                    </div>

                    <!-- <input v-model="bugtitle" placeholder="Title" style="width:70%"> -->
                    <!-- <Vueditor  ref="editor" placeholder="Description"></Vueditor> -->

                </div>
                <div class="modal-footer">
                    <button type="button" @click="forward" class="btn btn-primary">Submit</button>
                    <button type="button" @click="forwardclose" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                </div>
            </div>
            </div>
            </form>
        </div>

        <div style="text-align:center;">

            <img :src="image_src">

            <button type="button" @click="forwardClicked()" class="btn btn-outline-primary">Report Bugs / Request New Feature</button>

        </div>

</div>
</template>

<script>

    import { VueEditor } from "vue2-editor";

    import * as $ from "jquery";

    // import Vueditor from 'vueditor'

    // import 'vueditor/dist/style/vueditor.min.css'

    // let config = {
    //     toolbar: [
    //         'bold', 'italic', 'insertOrderedList', 'foreColor', 'link', '|', 'removeFormat', 'undo', 'redo'
    //     ],
    //     uploadUrl: '',
    // };

    // Vue.use(Vueditor, config);

    export default {
        components: {
            VueEditor
        },

        props: ['userid'],

        mounted() {
            console.log('Bug Tracking..')
        },

        data() {
            return {
                editor: '',
                bugtitle: '',
                bugtype: '',
                image_src: 'https://'+ location.hostname + '/images/help/bug.png',
                customToolbar: [
                    ["bold", "italic", "underline"],
                    [{ list: "ordered" }, { list: "bullet" }],
                ]
            };
        },

        methods: {

            forwardClicked() {
                $("#my-modal").modal('show');
            },

            forwardclose()
            {
                $("#my-modal").modal('hide');

                // Clearing Form
                this.bugtitle = '';
                this.bugtype = '';
                this.editor   = '';

            },

            forward()
            {
                const bugs = {
                    'bugtitle' : this.bugtitle,
                    'bugtype' : this.bugtype,
                    'bugdesc' : this.editor,
                    // 'userid' : this.userid,
                }

                if(!this.bugtitle == 0 && !this.bugtype == 0 && !this.editor.length == 0) {

                    axios.post('/bugtrack', {
                        bugs
                    })
                    .then(function (response) {
                        console.log('Success');
                        console.log(response);
                    })
                    .catch(function (error) {
                        console.log('Failure');
                        console.log(error);
                    });

                    $("#my-modal").modal('hide');

                    // Clearing Form
                    this.bugtitle = '';
                    this.bugtype = '';
                    this.editor   = '';

                }

            }
        }
    }
</script>
