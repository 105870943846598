<template>
    <div>

        <div class="form-group" id="msg-textarea">
            <div class="shadow p-3 mb-5 bg-white rounded">
                <div class="row">
                    <textarea class="form-control msgTextArea"  rows="6" placeholder="Have something to say ?" required v-model="newTask" ></textarea>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-12">
                <button type="submit" class="btn btn-primary"  @click="save" id="msg-btn"><b>SEND</b></button>
            </div>
        </div>

        <comment-chat :order="order" :myrole="myrole"></comment-chat>

    </div>

</template>

<script>

    import VueMomentsAgo from 'vue-moments-ago'

    import CommentChat from './CommentChat'

    import * as $ from "jquery";
    
    // import * as bootstrap from "bootstrap";

    export default {
        props: ['data-project', 'role'],
        data() {
            return {
                newTask: '',
                order: this.dataProject,
                myrole: this.role,
                message: '',
                orderid: '',
            };
        },

        components: {
            VueMomentsAgo,
            CommentChat
        },

        created() {

            window.Echo.private('chats.' + this.order.id)
                .listen('ChatCreated', ({chat}) => {
                    this.addTask(chat);
            });
            // .notification((notification) => {
            //     console.log(notification.type);
            // });

            // Echo.private('App.User.1')
            // .notification((notification) => {
            //     console.log(notification);
            // });

        },

        methods: {

            save() {

                // var instance = axios.create();
                // delete instance.defaults.headers.common['Authorization'];

                axios.post(`/api/myorders/${this.order.id}/chats`, {
                        body: this.newTask
                    })
                    .then(response => response.data)
                    .then(this.addTask);

                axios.post('https://www.submitinme.com/home/reseller_postmessage.aspx', {

                    SIM_Orderid: this.order.sim_order_id,

                    SIM_Msg: this.newTask
                })
                .then(function (response) {
                    console.log(response);
                })

            },

            addTask(chat) {
                this.order.chats.push(chat);
                this.newTask = '';
            }
        }
    }
</script>

<style scoped>

    .msgTextArea {
        background-image: linear-gradient(rgb(255,0,0,0.03),rgb(255,0,0,0.03));
        background: #fff;
        border: 1px solid #e1e1e1;
        min-height: 200px;
    }

</style>