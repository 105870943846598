<template>
    <div>

    <!-- TextArea Opens only When the Reply button is Clicked -->
    <div id="reply" class="modal fade">
        <form ref="myreplyForm">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title"><b><span style="color:#328af1;">REPLY AAAA</span>To</b></h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-header">
                    <!-- <span ><p v-html="myreply"></p></span> -->
                </div>

                <div class="modal-body">
                    <!-- <Vueditor  ref="editor1" ></Vueditor> -->

                    <vue-editor v-model="editor1" :editorToolbar="customToolbar"></vue-editor>

                </div>

                <div class="modal-footer">
                    <button type="button" @click="reply" class="btn btn-primary">Submit</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
        </form>
    </div>
    <!-- TextArea Ends -->

    <div v-for="(chat,index) in order.custompmbs" :key="chat.id" :index="index">

        <span v-if="order.custompmbs[index].reply_id === replyid">

            <div class="row">

                <div class="col-md-1"></div>

                <div class="col-md-1">
                    <img :src="chat.gravatar" class="rounded-circle" alt="Avatar" id="msg-img-style"/>
                </div>

                <div class="col-md-4" id="msg-img-name"><b>{{ chat.creator }}</b>
                <small id="msg-small-style"> &nbsp;
                    <vue-moments-ago prefix="said" suffix="ago" :date=chat.created_at lang="en"></vue-moments-ago>
                </small></div>

            </div>

            <div class="row">

                <div class="col-md-1"></div>

                <div class="col-md-11" id="container1" style="background-color:#F8F8FF">

                    <p style="white-space: pre-wrap;" v-html="chat.body"></p>
                    <span class="time-right">
                        <span v-if="myrole === 'accounts'">
                            <button @click="replyClicked(chat)" class="btn btn-link font-weight-bolder" style="text-decoration:none;font-size: 12px">REPLY AAA</button>
                        </span>
                        {{ chat.created_at | formatDate}} (UTC)
                    </span>

                </div>

            </div>

        </span>

    </div>

    </div>

</template>

<script>

    import VueMomentsAgo from 'vue-moments-ago'

    import * as $ from "jquery";

    export default {
        props: ['order', 'myrole', 'replyid'],

        components: {
            VueMomentsAgo
        },

        created() {

            // window.Echo.private('chats.' + this.order.id)
            //     .listen('ChatCreated', ({chat}) => {
            //         this.addTask(chat);
            // });

        },

        methods: {

            replyClicked: function(chat) {
                this.getreplyid = this.replyid;
                // this.myreply = chat.body;
                $("#reply").modal('show');
            },

            reply: function() {

                // if(!this.$refs.editor1.getContent() == 0) {
                if(!this.editor1.length() == 0) {

                axios.post(`/api/customorders/${this.order.id}/custompmbs`, {
                    body: this.editor1,
                    reply_id: this.getreplyid,
                })
                .then(response => response.data)
                .then(this.addTask);


                $("#reply").modal('hide');

                // Clearing Form
                this.editor1 = '';
                this.$refs.myreplyForm.reset();

                }

            },

            addTask(chat) {
                this.order.custompmbs.push(chat);
                this.newTask = '';
            }
        }
    }
</script>

<style scoped>

    #container1,
    .container1 {
    background-color: #f6f6f6;
    border-radius: 5px;
    margin: 10px 0;
    padding: 10px 0 0 0;
    border-bottom: 3px solid #c5c5c5;
    }

    .ClientMsgBox #container1,
    .ClientMsgBox .container1 {
    background-color: #dce8eb;
    border-bottom: 3px solid #b2c1c5;
    }

    #container1 p {
        padding: 10px 20px;
    }

    #container1 .time-right {
        color: #666;
        float: right;
        font-size: 12px;
        background: #dedede;
        border-radius: 10px 0 0 0;
        padding: 5px 20px;
    }

    .ClientMsgBox #container1 .time-right {
        color: #222;
        background: #b2c1c5;
        padding: 5px 20px;
    }

    #msg-btn {
        border-radius: 5px;
        float: right;
        padding: 15px 50px;
    }

    #container1 span .btn-outline-primary,
    .ClientMsgBox #container1 span .btn-outline-primary {
        margin: 10px 0 5px 15px
    }

</style>